import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout"
import ResponsiveSidebar from "../components/ResponsiveSidebar/ResponsiveSidebar"
import SidenavNavigation from "../components/SidenavNavigation/SidenavNavigation"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      excerpt
      html
      frontmatter {
        title
      }
    }
  }
`

const EnergiaFotovoltaicaTemplate = ({
  data: { markdownRemark: energiaFotovoltaica },
}) => {
  const sidenavNavigationListItems = [
    {
      title: "Módulos Fotovoltaicos",
      url: "/energia-fotovoltaica/modulos-fotovoltaicos",
    },
    {
      title: "Baterías de Acumulación",
      url: "/energia-fotovoltaica/baterias-acumulacion",
    },
    {
      title: "Convertidores / Reguladores",
      url: "/energia-fotovoltaica/convertidores-reguladores",
    },
    {
      title: "Bombeo Solar",
      url: "/energia-fotovoltaica/bombeo-solar",
    },
    {
      title: "Acceso a toma de datos",
      url: "/energia-fotovoltaica/acceso-toma-datos/datos-instalacion-aislada",
      subItems: [
        {
          title: "Datos para instalación aislada",
          url:
            "/energia-fotovoltaica/acceso-toma-datos/datos-instalacion-aislada",
        },
        {
          title: "Datos para Bombeo Solar Directo",
          url: "/energia-fotovoltaica/acceso-toma-datos/datos-bombeo-solar",
        },
      ],
    },
  ]

  return (
    <Layout>
      <div className="container">
        <div className="margin-y-lg">
          <div className="grid gap-xl">
            <div className="col-12">
              <h1 className="text-md">
                <Link
                  className="text-decoration-none"
                  to="/energia-fotovoltaica"
                >
                  Energía Fotovoltaica
                </Link>
              </h1>
            </div>
            <div className="col-12 col-4@sm">
              <ResponsiveSidebar title="Energía Fotovoltaica">
                <SidenavNavigation
                  sidenavNavigationListItems={sidenavNavigationListItems}
                />
              </ResponsiveSidebar>
            </div>
            <div className="col-12 col-8@sm">
              <div className="margin-y-sm text-component">
                <h2 className="text-sm">
                  {energiaFotovoltaica.frontmatter.title}
                </h2>
                <div
                  dangerouslySetInnerHTML={{ __html: energiaFotovoltaica.html }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EnergiaFotovoltaicaTemplate
